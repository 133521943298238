import React, { useState, useEffect } from 'react';
import { Cog } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { View, Text, Image, TextInput, TouchableOpacity, StyleSheet, Share } from 'react-native';
import pregnancyData from './pregnancyData.json';

const ConfigScreen = ({ dueDate, onSaveDueDate, onClose }) => {
  const [newDueDate, setNewDueDate] = useState(dueDate ? new Date(dueDate) : null);

  const handleSave = () => {
    onSaveDueDate(newDueDate.toISOString().split('T')[0]);
    onClose();
  };

  return (
    <View style={styles.configContainer}>
      <Text style={styles.label}>Select your due date:</Text>
      <DatePicker
        selected={newDueDate}
        onChange={(date) => setNewDueDate(date)}
        dateFormat="yyyy-MM-dd"
        minDate={new Date()}
        maxDate={new Date(Date.now() + 280 * 24 * 60 * 60 * 1000)} // roughly 40 weeks from now
      />
      <TouchableOpacity style={styles.button} onPress={handleSave}>
        <Text style={styles.buttonText}>Save</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.button} onPress={onClose}>
        <Text style={styles.buttonText}>Cancel</Text>
      </TouchableOpacity>
    </View>
  );
};

const ProgressBar = ({ week }) => {
    const progress = (week / 40) * 100;
    return (
      <View style={styles.progressContainer}>
        <View style={[styles.progressBar, { width: `${progress}%` }]} />
        <Text style={styles.progressText}>{`${week}/40 weeks`}</Text>
      </View>
    );
  };

  const DueDateApp = () => {
    const [dueDate, setDueDate] = useState('');
    const [content, setContent] = useState(null);
    const [showConfig, setShowConfig] = useState(false);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [displayWeek, setDisplayWeek] = useState(0);
  
    useEffect(() => {
      const storedDueDate = localStorage.getItem('dueDate');
      if (storedDueDate) {
        setDueDate(storedDueDate);
        updateContent(storedDueDate);
      } else {
        setShowConfig(true);
      }
    }, []);
  
    const updateContent = (date, weekOverride = null) => {
      const today = new Date();
      const due = new Date(date);
      const timeDiff = due.getTime() - today.getTime();
      const weeksDiff = Math.floor(timeDiff / (1000 * 3600 * 24 * 7));
      const week = weekOverride !== null ? weekOverride : 40 - weeksDiff;
      
      setCurrentWeek(40 - weeksDiff);
      setDisplayWeek(week);
  
      if (week >= 1 && week <= 40) {
        setContent(pregnancyData.pregnancyData[week - 1]);
      } else if (week > 40) {
        setContent({
          header: "Congratulations!",
          imageSrc: '/congrats.png',
          text: "Your baby should have arrived. We hope everything went well!",
          meat: "A bundle of joy"
        });
      } else {
        setContent({
          header: "Early Days",
          imageSrc: '/early.png',
          text: "Congratulations! You're in the early stages of your pregnancy.",
          meat: "A tiny miracle"
        });
      }
    };
  
    const handleSaveDueDate = (newDueDate) => {
      setDueDate(newDueDate);
      localStorage.setItem('dueDate', newDueDate);
      updateContent(newDueDate);
      setShowConfig(false);
    };
  
    const handlePreviousWeek = () => {
      if (displayWeek > 1) {
        updateContent(dueDate, displayWeek - 1);
      }
    };
  
    const handleNextWeek = () => {
      if (displayWeek < 40) {
        updateContent(dueDate, displayWeek + 1);
      }
    };
  
    return (
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          {content && <Text style={styles.header}>{content.header}</Text>}
          <TouchableOpacity style={styles.configButton} onPress={() => setShowConfig(true)}>
            <Cog size={24} color="#FFFFFF" />
          </TouchableOpacity>
        </View>
        {content && !showConfig && (
          <View style={styles.contentContainer}>
            <Image source={{ uri: content.imageSrc }} style={styles.image} />
            <Text style={styles.text}>{content.text}</Text>
            <ProgressBar week={displayWeek} />
            
            <View style={styles.navigationContainer}>
              <TouchableOpacity 
                style={[styles.navButton, displayWeek === 1 && styles.disabledButton]} 
                onPress={handlePreviousWeek}
                disabled={displayWeek === 1}
              >
                <Text style={styles.buttonText}>Previous Week</Text>
              </TouchableOpacity>
              <TouchableOpacity 
                style={[styles.navButton, displayWeek === 40 && styles.disabledButton]} 
                onPress={handleNextWeek}
                disabled={displayWeek === 40}
              >
                <Text style={styles.buttonText}>Next Week</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {showConfig && (
          <ConfigScreen 
            dueDate={dueDate} 
            onSaveDueDate={handleSaveDueDate} 
            onClose={() => setShowConfig(false)} 
          />
        )}
      </View>
    );
  };

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
      },
      configButton: {
        padding: 5,
      },
      header: {
        fontSize: 24,
        fontWeight: 'bold',
        flex: 1,
      },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  label: {
    fontSize: 18,
    marginBottom: 10,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  contentContainer: {
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  navigationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  navButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
    width: '45%',
  },
  disabledButton: {
    backgroundColor: '#A9A9A9',
  },
  configButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  },
  progressContainer: {
    width: '100%',
    marginTop: 20,
    height: 20,
    backgroundColor: '#e0e0e0',
    borderRadius: 10,
    marginBottom: 20,
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#4CAF50',
    borderRadius: 10,
  },
  progressText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    color: '#000',
    lineHeight: 20,
  },
  factContainer: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
  },
  factText: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
  configContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  label: {
    fontSize: 18,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
    width: 200,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  }
});

export default DueDateApp;